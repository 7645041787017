// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        window.location.hostname === '[::1]' ||
        /^127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)){3}$/.exec(window.location.hostname)
)

interface Config {
    onSuccess?: (registration: ServiceWorkerRegistration) => void
    onUpdate?: (registration: ServiceWorkerRegistration) => void
}

function registerValidSW(swUrl: string, config?: Config) {
    navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
            registration.onupdatefound = () => {
                const installingWorker = registration.installing
                if (installingWorker == null) {
                    return
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            console.log('New content is available and will be used when all ' + 'tabs for this page are closed. See https://bit.ly/CRA-PWA.')
                            if (config?.onUpdate) {
                                config.onUpdate(registration)
                            }
                        } else {
                            console.log('Content is cached for offline use.')
                            if (config?.onSuccess) {
                                config.onSuccess(registration)
                            }
                        }
                    }
                }
            }
        })
        .catch((error: unknown) => {
            console.error('Error during service worker registration:', error)
        })
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
    fetch(swUrl, {
        headers: { 'Service-Worker': 'script' }
    })
        .then((response) => {
            const contentType = response.headers.get('content-type')
            if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
                navigator.serviceWorker.ready
                    .then((registration) => {
                        registration
                            .unregister()
                            .then(() => {
                                window.location.reload()
                            })
                            .catch((error: unknown) => {
                                console.log(error)
                            })
                    })
                    .catch((error: unknown) => {
                        console.log(error)
                    })
            } else {
                registerValidSW(swUrl, config)
            }
        })
        .catch(() => {
            console.log('No internet connection found. App is running in offline mode.')
        })
}

export const register = (config?: Config): void => {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
        if (publicUrl.origin !== window.location.origin) {
            return
        }
        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
            if (isLocalhost) {
                checkValidServiceWorker(swUrl, config)
                navigator.serviceWorker.ready
                    .then(() => {
                        console.log('This web app is being served cache-first by a service ' + 'worker. To learn more, visit https://bit.ly/CRA-PWA')
                    })
                    .catch((error: unknown) => {
                        console.log(error)
                    })
            } else {
                registerValidSW(swUrl, config)
            }
        })
    }
}

export const unregister = (): void => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                void registration.unregister()
            })
            .catch((error: unknown) => {
                console.error(error)
            })
    }
}
